<template>
    <div>
        <h1 class="page__title">Создать отзыв</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-name" class="form-label">Название <sup>*</sup></label>
                    <input
                        id="form-name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.name"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-subtitle" class="form-label">Подзаголовок <sup>*</sup></label>
                    <input
                        id="form-subtitle"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.subtitle"
                    />
                </div>

                <div class="col-span-6">
                    <label class="form-label">Опубликовано <sup>*</sup></label>
                    <Multiselect
                        name="type"
                        :value="null"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        valueProp="id"
                        :options="[
                            { id: '0', name: 'Не опубликовано' },
                            { id: '1', name: 'Опубликовано' },
                        ]"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form.is_published"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label class="form-label">Дата публикации <sup>*</sup></label>
                    <date-picker
                        class="w-full"
                        mode="date"
                        timezone="utc"
                        is24hr
                        :popover="{ visibility: 'click' }"
                        :masks="masks"
                        v-model="form.publication_date"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                required
                                class="form-control focus:outline-none focus:border-blue-300 w-full"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </template>
                    </date-picker>
                </div>

                <div v-if="affiliates && affiliates.length > 0" class="col-span-6">
                    <label for="form-affiliates" class="form-label">Филиал <sup>*</sup></label>
                    <Multiselect
                        id="form-affiliates"
                        name="type"
                        :value="null"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        valueProp="id"
                        :options="affiliates"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form.affiliate_id"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-rating" class="form-label">Рейтинг <sup>*</sup></label>
                    <input
                        type="number"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        max="5"
                        min="0"
                        v-model="form.rating"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Изображение <sup>*</sup></label>
                    <UploadImage :image="imagePreview" v-model="form._image_file"></UploadImage>
                </div>

                <div class="col-span-12">
                    <label class="form-label">Полное описание <sup>*</sup></label>
                    <CustomWyswig v-model="form.body" />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="/reviews" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
                    <button type="button" class="btn btn-primary mr-2 mb-2" @click="() => save(true)">
                        Сохранить и продолжить
                    </button>
                    <button type="button" class="btn btn-primary mb-2" @click="save(false)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImage from '@/components/upload-image/UploadImage';
import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';
import dayjs from 'dayjs';

export default {
    components: { UploadImage, Preloader, CustomWyswig },
    name: 'Create',
    mixins: [errorResponse, formPreparation],
    data() {
        return {
            affiliates: [],
            loading: false,
            imagePreview: null,
            form: {
                name: '',
                subtitle: '',
                is_published: false,
                publication_date: '',
                body: '',
                rating: 0,
                affiliate_id: null,
            },
            masks: {
                inputDateTime24hr: ['DD.MM.YYYY'],
                input: ['DD.MM.YYYY'],
                inputTime24hr: ['HH:mm'],
            },
        };
    },
    async created() {
        await this.fetchAffiliates();
    },
    computed: {
        ...mapGetters({
            getUser: 'user/getUser',
        }),
    },
    methods: {
        async fetchAffiliates() {
            try {
                const ids =
                    this.getUser && this.getUser.assigned_affiliates && this.getUser.assigned_affiliates.length > 0
                        ? this.getUser.assigned_affiliates.join(',')
                        : null;
                const { data } = await this.axios.get('/affiliates', { params: { ids, paginate: false } });
                this.affiliates = data.data;
            } catch (ex) {
                console.error('cant fetch affiliates: ' + ex);
            }
        },
        clearForm() {
            for (const key in this.form) {
                if (key === 'published') {
                    this.form.published = false;
                    continue;
                }

                this.form[key] = null;
            }
        },
        async save(flag) {
            this.loading = true;
            try {
                this.loading = false;
                this.form.publication_date = dayjs(this.form.publication_date).format('YYYY-MM-DD');
                const formData = this.formPreparation();
                const { data } = await this.axios.post('/reviews', formData);

                if (flag) {
                    await this.$router.push({ name: 'reviewsEdit', params: { id: data.data.id } });
                    this.$notify('Запись добавлена');
                } else {
                    await this.$router.push({ name: 'reviews' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
    },
};
</script>

<style></style>
